import navbar from './en/navbar.json';
import aboutUs from './en/aboutUs.json';
import pack from './en/pack.json';
import footer from './en/footer.json';
import contact from './en/contact.json';
import presentation from './en/presentation.json';

const translations ={
  navbar,
  aboutUs,
  pack,
  footer,
  contact,
  presentation
}

export default translations;