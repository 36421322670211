import Footer from '../../components/Footer';
import ContactForm from '../../components/ContactForm';
import Navbar from '../../components/Navbar';
import '../../styles/fonts.scss';
import './index.scss';

const Contact = () => {
  return (
    <div className='contactPage'>
      <Navbar/>
      <h1>Ponte en contacto con nosotros</h1>
      <ContactForm/>
      <Footer />
    </div>
  );
}

export default Contact;
