import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import logo from '../../assets/laVisualTV.jpg'; // Asegúrate de tener el logo en tu directorio de imágenes

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="sidebar">
      <div className="sidebar-topbar">
        <div className="sidebar-logo">
          <img src={logo} alt="Logo" />
        </div>
        <button className="sidebar-toggle" onClick={toggleSidebar}>
          ☰
        </button>
      </div>
      <nav className={`sidebar-menu ${isOpen ? 'open' : ''}`}>
        <ul>
          <li><Link to="/backoffice/texts" onClick={toggleSidebar}>Textos</Link></li>
          <li><Link to="/backoffice/contacts" onClick={toggleSidebar}>Contactos</Link></li>
          <li className="mobile-only"><Link to="/logout" onClick={toggleSidebar}>Logout</Link></li>
        </ul>
      </nav>
      <Link className="logout-button desktop-only" to={'/'}>Logout</Link>
    </div>
  );
};

export default Sidebar;
