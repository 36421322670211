import React, { useState } from 'react';
import Sidebar from '../../components/Sidebar';
import './index.scss';
import video from '../../assets/SampleVideo.mp4';
import { getLocale, setLocale, translate } from '../../utils/translate/translator';

const Textos = () => {
  const [language, setLanguage] = useState(getLocale());
  const aboutUsData = [
    {
      id: 1,
      title: translate('aboutUs.installation.title'),
      text: translate('aboutUs.installation.text'),
      text2: translate('aboutUs.installation.text2'),
      titleList: translate('aboutUs.installation.titleList'),
      point1: translate('aboutUs.installation.point1'),
      point2: translate('aboutUs.installation.point2'),
      text3: translate('aboutUs.installation.text3')
    },
    {
      id: 2,
      title: translate('aboutUs.advantage.title'),
      point1: translate('aboutUs.advantage.point1'),
      point2: translate('aboutUs.advantage.point2'),
      point3: translate('aboutUs.advantage.point3'),
      point4: translate('aboutUs.advantage.point4'),
      point5: translate('aboutUs.advantage.point5')
    },
    {
      id: 3,
      title: translate('aboutUs.rate.title'),
      point1: translate('aboutUs.rate.point1'),
      point2: translate('aboutUs.rate.point2'),
      point3: translate('aboutUs.rate.point3'),
      point4: translate('aboutUs.rate.point4')
    }
  ];

  const packs = [
    {
      title: translate('pack.packStart5.title'),
      point1: translate('pack.packStart5.point1'),
      point2: translate('pack.packStart5.point2'),
      point3: translate('pack.packStart5.point3'),
      text: translate('pack.packStart5.text'),
      price: 460
    },
    {
      title: translate('pack.packStart10.title'),
      point1: translate('pack.packStart10.point1'),
      price: 750
    },
    {
      title: translate('pack.packStart15.title'),
      point1: translate('pack.packStart15.point1'),
      price: 900
    },
    {
      title: translate('pack.content1.title'),
      point1: translate('pack.content1.point1'),
      price: 120
    },
  ];

  return (
    <div className="textos-page">
      <Sidebar />
      <div className="content">
        <h1>{language === 'en' ? 'Modify Web Content' : 'Modificar Contenido Web'}</h1>
        <div className="language-selector">
          <label htmlFor="language">Idioma: </label>
          <select id="language" value={language} onChange={(e) => {
            setLanguage(e.target.value);
            setLocale(e.target.value);
          }}>
            <option value="en">English</option>
            <option value="es">Español</option>
            <option value="es">+ Añadir Idioma</option>
          </select>
        </div>

        <div className="homepage-video">
          <label>Video para el homepage</label>
          <div className="video-container">
            <video src={video} controls />
            <input type="file" accept="video/*" />
            <button className="change-video-btn" onClick={() => document.querySelector('input[type="file"]').click()}>
              Cambiar Video
            </button>
          </div>
        </div>

        <div className="section-header">
          <h2>{language === 'en' ? 'How We Work' : 'Cómo funcionamos'}</h2>
          <button className="add-section-btn">{language === 'en' ? 'Add Section +' : 'Añadir Apartado +'}</button>
        </div>

        {aboutUsData.map((item) => (
          <div key={item.id} className="how-we-work-box">
            <div className="textarea-container">
              <label>{language === 'en' ? 'Title' : 'Título'}</label>
              <textarea
                value={item.title}
              />
            </div>
            {item.text && (
              <div className="textarea-container">
                <label>{language === 'en' ? 'Text' : 'Texto'}</label>
                <textarea
                  value={item.text}
                />
              </div>
            )}
            {item.text2 && (
              <div className="textarea-container">
                <label>{language === 'en' ? 'Text 2' : 'Texto 2'}</label>
                <textarea
                  value={item.text2}
                />
              </div>
            )}
            {item.titleList && (
              <div className="textarea-container">
                <label>{language === 'en' ? 'Title List' : 'Título de la Lista'}</label>
                <textarea
                  value={item.titleList}
                />
              </div>
            )}
            {item.point1 && (
              <div className="textarea-container">
                <label>{language === 'en' ? 'Point 1' : 'Punto 1'}</label>
                <textarea
                  value={item.point1}
                />
              </div>
            )}
            {item.point2 && (
              <div className="textarea-container">
                <label>{language === 'en' ? 'Point 2' : 'Punto 2'}</label>
                <textarea
                  value={item.point2}
                />
              </div>
            )}
            {item.text3 && (
              <div className="textarea-container">
                <label>{language === 'en' ? 'Text 3' : 'Texto 3'}</label>
                <textarea
                  value={item.text3}
                />
              </div>
            )}
            {item.point3 && (
              <div className="textarea-container">
                <label>{language === 'en' ? 'Point 3' : 'Punto 3'}</label>
                <textarea
                  value={item.point3}
                />
              </div>
            )}
            {item.point4 && (
              <div className="textarea-container">
                <label>{language === 'en' ? 'Point 4' : 'Punto 4'}</label>
                <textarea
                  value={item.point4}
                />
              </div>
            )}
            {item.point5 && (
              <div className="textarea-container">
                <label>{language === 'en' ? 'Point 5' : 'Punto 5'}</label>
                <textarea
                  value={item.point5}
                />
              </div>
            )}
          </div>
        ))}


        <div className="section-header">
          <h2>{language === 'en' ? 'Packs' : 'Paquetes'}</h2>
          <button className="add-section-btn">{language === 'en' ? 'Add Pack +' : 'Añadir Paquete +'}</button>
        </div>

        {packs.map((pack, idx) => (
          <div key={idx} className="pack-box">
            <div className="textarea-container">
              <label>{language === 'en' ? 'Title' : 'Título'}:</label>
              <textarea
                value={pack.title}
              />
            </div>
            <div className="textarea-container">
              <label>{language === 'en' ? 'Point 1' : 'Punto 1'}:</label>
              <textarea
                value={pack.point1}
              />
            </div>
            {pack.point2 && (
              <div className="textarea-container">
                <label>{language === 'en' ? 'Point 2' : 'Punto 2'}:</label>
                <textarea
                  value={pack.point2}
                />
              </div>
            )}
            {pack.point3 && (
              <div className="textarea-container">
                <label>{language === 'en' ? 'Point 3' : 'Punto 3'}:</label>
                <textarea
                  value={pack.point3}
                />
              </div>
            )}
            {pack.point4 && (
              <div className="textarea-container">
                <label>{language === 'en' ? 'Point 4' : 'Punto 4'}:</label>
                <textarea
                  value={pack.point4}
                />
              </div>
            )}
            {pack.text && (
              <div className="textarea-container">
                <label>{language === 'en' ? 'Text' : 'Texto'}:</label>
                <textarea
                  value={pack.text}
                />
              </div>
            )}
            <div className="price-container">
              <label>{language === 'en' ? 'Price' : 'Precio'}:</label>
              <input
                type="number"
                value={pack.price}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Textos;
