import React, { forwardRef } from "react";
import './index.scss';

const Section = forwardRef(({ children, classNameSection, classNameContent }, ref) => (
  <div ref={ref} className={`section ${classNameSection}`}>
    <div className={`content ${classNameContent}`}>
      {children}
    </div>
  </div>
));

export default Section;