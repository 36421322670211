import navbar from './es/navbar.json';
import aboutUs from './es/aboutUs.json';
import pack from './es/pack.json';
import footer from './es/footer.json';
import contact from './es/contact.json';
import presentation from './es/presentation.json';

const translations = {
  navbar,
  aboutUs,
  pack,
  footer,
  contact,
  presentation
}

export default translations;