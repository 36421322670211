import React from 'react';
import './index.scss';
import { translate } from '../../../../utils/translate/translator';
import LayerCard from '../../../LayerCard';

const PresentationCard = ({ data }) => {
  return (
    <div className={`presentatCardConent ${data.reverse ? 'reverse' : ''}`}>
      <div className='imageCardWrapper'>
        <img src={data?.image1} alt={translate('presentation.alt')} />
      </div>
      <div className='contentWrapper'>
        <div className='titleCardWrapper'>
          <h3 className={`${data.colorTitle}`}>{data.title1} <span className={`${data.colorTitle}-color`}>{data.title2}</span></h3>
        </div>
        <div className={`textWrapper ${data.colorTitle}`}>
        <LayerCard className='card' colorFront='primary' colorMiddle='tertiary' colorBack='secondary'>
          {data.texts.map((text, key) => (
            <p key={key}>{text}</p>
          ))}
        </LayerCard>
      </div>
      <div className='messageWrapper'>
        <p>{data.percent}</p>
        <p>{data.message}</p>
      </div>
    </div>
    </div >
  );
}

export default PresentationCard;
