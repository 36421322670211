import React from 'react';
import './index.scss';

const LayerCard = ({ children, className, colorFront, colorMiddle, colorBack, isPack = false }) => {
  return (
    <div className={`layer-card ${className} ${isPack ? 'isPack' : ''}`}>
      <div className={`layer1 ${colorFront}-bg`}>
        {children}
      </div>
      <div className={`layer2 ${colorMiddle}-bg`}></div>
      <div className={`layer3 ${colorBack}-bg`}></div>
    </div>
  )
}

export default LayerCard;