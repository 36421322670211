/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

/**
 * This custom hook recieve the ref of an element. When the observer detects that the node is in the viewport, set intersecting true.
 * @param {*} ref
 */
const useScreenEnter = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if(!ref.current) return;
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => observer.disconnect();
  }, []);

  return isIntersecting;
};

export default useScreenEnter;
