import React, { useRef } from 'react';
import './index.scss';
import Section from '../../Section';
import PresentationCard from './PresentationCard';
import img1 from '../../../assets/presentation/presentation1.jpg'
import img2 from '../../../assets/presentation/presentation2.jpg'
import img3 from '../../../assets/presentation/presentation3.jpg'
import img4 from '../../../assets/presentation/presentation4.jpg'
import img5 from '../../../assets/presentation/presentation5.jpg'
import img6 from '../../../assets/presentation/presentation6.jpg'
import { translate } from '../../../utils/translate/translator';
import useScreenEnter from '../../../hooks/useScreenEnter';

const Presentation = () => {
  const presentationRef = useRef();
  const isVisible = useScreenEnter(presentationRef);

  const values = [
    {
      title1: translate('presentation.presentation1.title1'),
      title2: translate('presentation.presentation1.title2'),
      image1: img1,
      image2: img2,
      texts: [translate('presentation.presentation1.text1')],
      percent: "400%",
      message: translate('presentation.presentation1.message'),
      colorTitle: "secondary",
      reverse: false
    },
    {
      title1: translate('presentation.presentation2.title1'),
      title2: translate('presentation.presentation2.title2'),
      image1: img3,
      image2: img4,
      texts: [translate('presentation.presentation2.text1'), translate('presentation.presentation2.text2')],
      colorTitle: "tertiary",
      reverse: true
    },
    {
      title1: translate('presentation.presentation3.title1'),
      title2: translate('presentation.presentation3.title2'),
      image1: img5,
      image2: img6,
      texts: [translate('presentation.presentation3.text1')],
      percent: "86%",
      message: translate('presentation.presentation3.message'),
      colorTitle: "primary",
      reverse: false
    }
  ]

  return (
    <Section classNameSection="presentation" classNameContent="content">
      <div ref={presentationRef} className={`titleWrapper ${isVisible ? 'activeAction' : ''}`}>
        <h2>
          <p>{translate('presentation.title1')}</p>
          <p>{translate('presentation.title2')}</p>
          <p>{translate('presentation.title3')}</p>
        </h2>
      </div>
      {values.map((value, key) => (
        <PresentationCard key={key} data={value} />
      ))}
    </Section>
  );
}

export default Presentation;
