import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './index.scss';

import logoHorizontal from '../../assets/3.laVisualTVColor-Horizontal.svg';
import menu from '../../assets/menu.png';
import { translate } from '../../utils/translate/translator';
import LanguageSelector from '../LanguageSelector';

const Navbar = ({ aboutUsRef, contactRef }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef();

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (navbarRef?.current && !navbarRef.current.contains(e.target) && isOpen) {
        setIsOpen(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('scroll', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('scroll', handleClickOutside);
    };
  }, [navbarRef, isOpen]);

  const onClickLink = (ref) => {
    if (ref?.current) {
      const componentTop = ref.current.getBoundingClientRect().top;
      const navbarHeight = 64;
      const scrollY = window.pageYOffset || document.documentElement.scrollTop;
      const targetPosition = componentTop + scrollY - navbarHeight;

      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
    }
    setIsOpen(false);
  };

  return (
    <div className="navbar" ref={navbarRef}>
      <Link to="/">
        <img src={logoHorizontal} alt="logo"></img>
      </Link>
      <div className={`links ${isOpen ? 'open' : ''}`}>
        <Link to="/" onClick={() => onClickLink(aboutUsRef)}>
          {translate('navbar.advantages')}
        </Link>
        <Link to="/tariffs" onClick={() => onClickLink()}>
          {translate('navbar.rates')}
        </Link>
        <Link to="/contact" onClick={() => onClickLink()}>
          {translate('navbar.contact')}
        </Link>
        <LanguageSelector />
      </div>
      <button className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
        <img src={menu} alt="menu-logo"></img>
      </button>
    </div>
  )
}

export default Navbar;
