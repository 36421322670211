import React from 'react';
import './index.scss';
import LayerCard from '../../LayerCard';
import { translate } from '../../../utils/translate/translator';
import Section from '../../Section';

const AboutUs = ({ aboutUsRef }) => {
  return (
    <Section classNameSection="aboutUs" classNameContent="content" ref={aboutUsRef}>
      <h2>{translate('aboutUs.title1')} <span>{translate('aboutUs.title2')}</span></h2>
      <div className='cards'>
        <LayerCard className='card' colorFront='primary' colorMiddle='tertiary' colorBack='secondary'>
          <h3>{translate('aboutUs.installation.title')}</h3>
          <div className='firstPharagraph'>
            <p>{translate('aboutUs.installation.text')} <strong>{translate('aboutUs.installation.text2')}</strong>.</p>
          </div>
          <div className='secondPharagraph'>
            <p>{translate('aboutUs.installation.titleList')}</p>
            <ul>
              <li>{translate('aboutUs.installation.point1')}</li>
              <li>{translate('aboutUs.installation.point2')}</li>
            </ul>
            <p>{translate('aboutUs.installation.text3')}</p>
          </div>
        </LayerCard>
        <LayerCard className='card' colorFront='tertiary' colorMiddle='secondary' colorBack='primary'>
          <h3>{translate('aboutUs.advantage.title')}</h3>
          <ul>
            <li>{translate('aboutUs.advantage.point1')}</li>
            <li>{translate('aboutUs.advantage.point2')}</li>
            <li>{translate('aboutUs.advantage.point3')}</li>
            <li>{translate('aboutUs.advantage.point4')}</li>
            <li>{translate('aboutUs.advantage.point5')}</li>
          </ul>
        </LayerCard>
        <LayerCard className='card' colorFront='secondary' colorMiddle='primary' colorBack='tertiary'>
          <h3>{translate('aboutUs.rate.title')}</h3>
          <ul>
            <li>{translate('aboutUs.rate.point1')}</li>
            <li>{translate('aboutUs.rate.point2')}</li>
            <li>{translate('aboutUs.rate.point3')}</li>
            <li>{translate('aboutUs.rate.point4')}</li>
          </ul>
        </LayerCard>
      </div>
    </Section>
  )
}

export default AboutUs;
