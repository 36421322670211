import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '@fortawesome/fontawesome-pro/css/all.min.css';
import '@wozzocomp/base-comps/dist/index.css';
import './styles/fonts.scss';
import './styles/index.scss';
import Homepage from './pages/Homepage';
import Login from './pages/Login';
import TextsBackoffice from './pages/TextsBackoffice';
import ContactsBackoffice from './pages/ContactsBackoffice';
import Tariffs from './pages/Tariffs';
import Contact from './pages/Contact';
import ScrollToTop from './components/ScrollToTop';

const App = () => (
  <Router>
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/tariffs" element={<Tariffs />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/login" element={<Login />} />
      <Route path="/backoffice/texts" element={<TextsBackoffice/>} />
      <Route path="/backoffice/contacts" element={<ContactsBackoffice/>} />
    </Routes>
  </Router>
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
