import React, { useState } from 'react';
import axios from 'axios';
import './index.scss';
import img from '../../assets/1.laVisualColor.svg'
import Section from '../Section';
import { translate } from '../../utils/translate/translator';

const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const { REACT_APP_LINK_CONTACT } = process.env

const ContactForm = ({ contactRef }) => {
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [showError, setShowError] = useState({});
  const [form, setForm] = useState({ agreement: false });
  const sendEmail = () => {
    let errs = { hasErrors: false };
    if (!form.name) {
      errs.hasErrors = true;
      errs.name = true;
    }
    if (!form.company) {
      errs.hasErrors = true;
      errs.company = true;
    }
    if (!form.email || !isValidEmail(form?.email)) {
      errs.hasErrors = true;
      errs.email = true;
    }
    if (!form.question) {
      errs.hasErrors = true;
      errs.question = true;
    }
    if (!form.description) {
      errs.hasErrors = true;
      errs.description = true;
    }
    if (!form.agreement) {
      errs.hasErrors = true;
      errs.agreement = true;
    }
    
    if (!errs.hasErrors) {
      setLoading(true);
      const data = JSON.stringify({
        adminEmail: 'info@lavisualstudio.com',
        name: form?.name,
        company: form?.company,
        email: form?.email,
        question: form?.question,
        description: form?.description,
        meet: form?.meet,
        agreement: form?.agreement
      });
      axios.post(`${REACT_APP_LINK_CONTACT}`, data).then(() => {
        setEmailSent(true);
      })
        .finally(() => {
          setLoading(false);
        });

    }
    setShowError(errs);
  };
  const changeForm = (key, e) => {
    if (key === "agreement") {
      setForm({ ...form, [key]: e });

    } else {
      setForm({ ...form, [key]: e?.target?.value });
    }
    const { [key]: _, ...updatedErrors } = showError;
    setShowError(updatedErrors);
  };

  return (
    <Section classNameSection="contact" classNameContent="content" ref={contactRef}>
      <div className='imageContent' >
        <img src={img} alt="imga" />
      </div >
      <div className="formContent">
        <div className='form'>
          <div className="row1">
            <input
              type="text"
              placeholder={translate('contact.placeholder.name')}
              className={showError.name ? 'error' : ''}
              value={form?.name}
              onChange={(e) => changeForm('name', e)}
            />
            <input
              type="tel"
              placeholder={translate('contact.placeholder.company')}
              value={form?.company}
              className={showError.company ? 'error' : ''}
              onChange={(e) => changeForm('company', e)}
            />
          </div>
          <div className='row2'>
            <input
              type="email"
              placeholder={translate('contact.placeholder.email')}
              value={form?.email}
              className={showError.email ? 'error' : ''}
              onChange={(e) => changeForm('email', e)}
            />
            <input
              type="text"
              placeholder={translate('contact.placeholder.question')}
              value={form?.question}
              className={showError.question ? 'error' : ''}
              onChange={(e) => changeForm('question', e)}
            />
          </div>
          <div className='textarea'>
            <textarea
              type="text"
              rows={8}
              placeholder={translate('contact.placeholder.textarea')}
              value={form?.description}
              className={showError.description ? 'error' : ''}
              onChange={(e) => changeForm('description', e)}
            />
          </div>
          <input
            type="text"
            placeholder={translate('contact.placeholder.meet')}
            value={form?.meet}
            onChange={(e) => changeForm('message', e)}
          />
          <label htmlFor="agreement" id='label'>
            <input
              type="checkbox"
              id="agreement"
              value={form?.agreement}
              onChange={() => changeForm('agreement', !form.agreement)}
              className={showError.agreement ? 'error' : ''}
            /> {translate('contact.agreement')}</label>
        </div>
        {!emailSent ? <button disabled={loading} onClick={sendEmail}>{loading ? '...' : `${translate('contact.send')}`}</button> : <div className="success-message">{translate('contact.sendOK')}</div>}
        {showError.hasErrors && <div className="error-message">{translate('contact.sendError')}</div>}
      </div>
    </Section >
  )
}

export default ContactForm;
