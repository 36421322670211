import React from "react";
import { LANGUAGES, getLocale, setLocale } from "../../utils/translate/translator";
import { BUTTON_TYPES, DropdownButton } from '@wozzocomp/base-comps';
import './index.scss'

const LanguageSelector = () => {

  const localeLanguage = getLocale();

  const LANGUAGES_TEXTS = {
    es: 'Español',
    en: 'English'
  }
  const changeLanguage = (selectedLang) => {
    if (localeLanguage !== selectedLang.value) {
      setLocale(selectedLang.value)
      window.location.reload();
    }
  }
  const LOCALES_LIST = LANGUAGES.map((loc) => ({ text: LANGUAGES_TEXTS[loc.value], onClick: () => changeLanguage(loc) }))


  return (
    <div className="languageSelector">
      <DropdownButton
        iconLeft="fal fa-globe"
        text={localeLanguage}
        type={BUTTON_TYPES.transparent}
        elements={LOCALES_LIST}
      />
      {/* {LANGUAGES.map((lang, idx) => (
          <button key={idx} onClick={() => changeLanguage(lang.value)}>{lang.value}</button>
        ))} */}
      {/* <select value={localeLanguage} onChange={(e) => changeLanguage(e.target.value)}>
        {LANGUAGES.map((lang, idx) => (
          <option key={idx} value={lang.value}>{lang.value}</option>
        ))}
      </select> */}
      {/* <button onClick={() => setSelected(!selected)}>
        <img src={img} alt="imag" />{localeLanguage}</button>
      <div className={`options ${selected ? 'show': ''}`}>
        {LANGUAGES.map((lang, idx) => (
          <button key={idx} onClick={() => changeLanguage(lang.value)}>{lang.value}</button>
        ))}
      </div> */}
    </div>
  )
}
export default LanguageSelector;