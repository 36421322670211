import React from 'react';
import './index.scss';
import img from '../../assets/laVisualTV.svg';
import youtube from '../../assets/youtube.png';
import instagram from '../../assets/instagram.png';
import linkedin from '../../assets/linkedin.png';
import { getLocale, translate } from '../../utils/translate/translator';
import { DevelopedByWozzo } from '@wozzocomp/base-comps';
import image1 from '../../assets/logorecuperacion.png'
import image2 from '../../assets/EN_Funded_by_the_European_Union_RGB_NEG.png'

const Footer = ({ contactRef }) => {
  const localeLanguage = getLocale();

  return (
    <div className="footer">
      <div className='contentFooterWrapper'>
        <div className='imageContent'>
          <img src={img} alt='imag' />
          <div className='textContent'>
            <p>{translate('footer.text1')}</p>
            <p>{translate('footer.text2')}</p>
            <p>{translate('footer.text3')}</p>
          </div>
        </div>
        <div className='linksContent'>
          <div className='socialMedia'>
            <a href='https://www.instagram.com/lavisualstudio/' target='_blank' rel="noreferrer">
              <img src={instagram} alt={translate('footer.imageAlt')} />
            </a>
            <a href='https://www.youtube.com/c/LAVISUAL' target='_blank' rel="noreferrer">
              <img src={youtube} alt={translate('footer.imageAlt')} />
            </a>
            <a href='https://www.linkedin.com/company/lavisualstudio/' target='_blank' rel="noreferrer">
              <img src={linkedin} alt={translate('footer.imageAlt')} />
            </a>
          </div>
        </div>
      </div>
      <div className='kitDigitalWrapper'>
        <h4>{translate('footer.kitDigital.title1')} - {translate('footer.kitDigital.title2')}</h4>
        <div className='kitDigitalImagesWrapper'>
          <img src={image1} alt="" />
          <img src={image2} alt="" />
        </div>
      </div>
      <DevelopedByWozzo lang={localeLanguage} />
    </div>
  )
}

export default Footer
