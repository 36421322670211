import React from 'react';
import './index.scss';
import video from '../../../assets/SampleVideo.mp4'

const TopSection = () => (
  <div className="top-section">
    <video autoPlay muted loop playsInline>
      <source src={video} type="video/mp4" />
    </video>
  </div>
)

export default TopSection
