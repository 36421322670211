import { useRef } from 'react';
import Footer from '../../components/Footer';
import AboutUs from '../../components/Homepage/AboutUs';
import ContactForm from '../../components/ContactForm';
import TopSection from '../../components/Homepage/TopSection';
import Navbar from '../../components/Navbar';
import '../../styles/fonts.scss';
import Packs from '../../components/Packs';
import Presentation from '../../components/Homepage/Presentation';

const Homepage = () => {
  const aboutUsRef = useRef();
  const packRef = useRef();
  const contactRef = useRef();
  return (
    <div className="homepage">
      <Navbar aboutUsRef={aboutUsRef} contactRef={contactRef} />
      <TopSection />
      <Presentation />
      <AboutUs aboutUsRef={aboutUsRef} />
      <Packs packRef={packRef} />
      <ContactForm contactRef={contactRef} />
      <Footer />
    </div>
  );
}

export default Homepage;
