import Footer from '../../components/Footer';
import Contact from '../../components/ContactForm';
import Navbar from '../../components/Navbar';
import '../../styles/fonts.scss';
import Packs from '../../components/Packs';
import './index.scss';

const Tariffs = () => {
  return (
    <div className='tariffsPage'>
      <Navbar/>
      <Packs/>
      <Contact/>
      <Footer />
    </div>
  );
}

export default Tariffs;
