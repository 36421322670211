import React from 'react';
import Sidebar from '../../components/Sidebar';
import './index.scss';

const ContactsBackoffice = () => {
  const contacts = [
    { id: 1, firstName: 'Carlos', lastName: 'García', email: 'carlos.garcia@tiendacarlos.com', phone: '600-123-456', message: 'Hola, estoy interesado en contratar videos para las televisiones de mi tienda.' },
    { id: 2, firstName: 'María', lastName: 'Martínez', email: 'maria.martinez@restaurantesolana.es', phone: '600-234-567', message: 'Me gustaría saber más sobre sus videos para poner en los televisores de mi restaurante.' },
    { id: 3, firstName: 'Luis', lastName: 'López', email: 'luis.lopez@negociolopez.net', phone: '600-345-678', message: 'Estoy buscando videos para televisores en mi negocio, ¿podrían darme más información?' },
    { id: 4, firstName: 'Ana', lastName: 'Sánchez', email: 'ana.sanchez@restauranteana.com', phone: '600-456-789', message: 'Quisiera contratar videos para las pantallas de mi restaurante, ¿qué opciones tienen?' },
    { id: 5, firstName: 'Javier', lastName: 'Fernández', email: 'javier.fernandez@tiendajavier.com', phone: '600-567-890', message: 'Estoy interesado en sus servicios de videos para las televisiones en mi tienda de ropa.' },
    { id: 6, firstName: 'Lucía', lastName: 'Ruiz', email: 'lucia.ruiz@cafeteriaruiz.com', phone: '600-678-901', message: 'Necesito videos promocionales para las televisiones en mi cafetería, ¿pueden ayudarme?' },
    { id: 7, firstName: 'Pedro', lastName: 'Hernández', email: 'pedro.hernandez@tiendaspedro.es', phone: '600-789-012', message: 'Hola, quisiera información sobre sus videos para televisores en tiendas.' },
    { id: 8, firstName: 'Laura', lastName: 'Ramírez', email: 'laura.ramirez@restaurantebuenosaires.net', phone: '600-890-123', message: 'Me gustaría contratar sus videos para los televisores en mi restaurante.' },
    { id: 9, firstName: 'Alberto', lastName: 'González', email: 'alberto.gonzalez@comercialalberto.com', phone: '600-901-234', message: 'Estoy interesado en videos para televisiones en mi comercio, ¿podrían enviarme más detalles?' },
    { id: 10, firstName: 'Sofía', lastName: 'Vázquez', email: 'sofia.vazquez@boutiquesofia.es', phone: '600-012-345', message: 'Necesito videos para los televisores en mi boutique, ¿qué opciones tienen?' }
  ];
  

  return (
    <div className="contacts-backoffice-page">
      <Sidebar />
      <div className="content">
        <h1>Contactos</h1>
        <table className="contacts-table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Email</th>
              <th>Teléfono</th>
              <th>Mensaje</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map(contact => (
              <tr key={contact.id}>
                <td>{contact.firstName}</td>
                <td>{contact.lastName}</td>
                <td>{contact.email}</td>
                <td>{contact.phone}</td>
                <td>{contact.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContactsBackoffice;
