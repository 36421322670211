import React from 'react';
import './index.scss';
import LayerCard from '../LayerCard';
import { translate } from '../../utils/translate/translator';
import Section from '../Section';

const Packs = ({ packRef }) => {
  return (
    <Section classNameSection="pack" classNameContent="content" ref={packRef}>
      <h2>{translate('pack.title1')} <span>{translate('pack.title2')}</span></h2>
      <div className='cards'>
        <LayerCard className='card' colorFront='primary' colorMiddle='tertiary' colorBack='secondary' isPack>
          <div className='installation'>
            <h3>{translate('pack.installation.title')}</h3>
            <ul>
              <li>{translate('pack.installation.point1')}</li>
              <li>{translate('pack.installation.point2')}</li>
            </ul>
            <p><strong>{`${translate('pack.installation.text')}: ${"100€/TV"}`}</strong></p>
          </div>
          <div className='subscription'>
            <h3>{translate('pack.subscription.title')}</h3>
            <ul>
              <li>{translate('pack.subscription.point1')}</li>
              <li>{translate('pack.subscription.point2')}</li>
              <li>{translate('pack.subscription.point3')}</li>
            </ul>
            <p><strong>{translate('pack.subscription.text')}</strong></p>
          </div>
          <p className='priceText'><strong>{"250€/TV"}</strong></p>
        </LayerCard>
        <LayerCard className='card' colorFront='white' colorMiddle='secondary' colorBack='primary' isPack>
          <h3>{translate('pack.packStart5.title')}</h3>
          <ul>
            <li>{`${translate('pack.packStart5.point1')}`} <strong>{translate('pack.packStart5.point1Strong')}</strong> {`${translate('pack.packStart5.point1End')}`}</li>
            <li>{translate('pack.packStart5.point2')}</li>
            <li>{translate('pack.packStart5.point3')}</li>
          </ul>
          <p className='messageText'>{translate('pack.packStart5.text')}</p>
          <p className='priceText'><strong>{"460€"}</strong></p>
        </LayerCard>
        <LayerCard className='card' colorFront='white' colorMiddle='primary' colorBack='tertiary' isPack>
          <h3>{translate('pack.packStart10.title')}</h3>
          <ul className='list'>
            <li>{`${translate('pack.packStart10.point1')}`} <strong>{translate('pack.packStart10.point1Strong')}</strong> {`${translate('pack.packStart10.point1End')}`}</li>
            <li>{translate('pack.packStart10.point2')}</li>
            <li>{translate('pack.packStart10.point3')}</li>
            <li>{translate('pack.packStart10.point4')}</li>
          </ul>
          <p className='messageText'>{translate('pack.packStart10.text')}</p>
          <p className='priceText'><strong>{"750€"}</strong></p>
        </LayerCard>
        <LayerCard className='card' colorFront='white' colorMiddle='secondary' colorBack='tertiary' isPack>
          <h3>{translate('pack.packStart15.title')}</h3>
          <ul className='list'>
            <li>{`${translate('pack.packStart15.point1')}`} <strong>{translate('pack.packStart15.point1Strong')}</strong> {`${translate('pack.packStart15.point1End')}`}</li>
            <li>{translate('pack.packStart15.point2')}</li>
            <li>{translate('pack.packStart15.point3')}</li>
            <li>{translate('pack.packStart15.point4')}</li>
          </ul>
          <p className='messageText'>{translate('pack.packStart15.text')}</p>
          <p className='priceText'><strong>{"900€"}</strong></p>
        </LayerCard>
        <LayerCard className='card' colorFront='white' colorMiddle='tertiary' colorBack='primary' isPack>
          <h3>{translate('pack.content1.title')}</h3>
          <ul>
            <li>{`${translate('pack.content1.point1')}`}</li>
          </ul>
          <p className='messageText'>{translate('pack.content1.text')}</p>
          <p className='priceText'><strong>{"120€"}</strong></p>
        </LayerCard>
      </div>
    </Section>
  )
}

export default Packs;
